import React from 'react'
import styles from './Services.module.scss'
import { motion } from "framer-motion"
import icon_1 from '../../image/Portfolio/img_8.webp'
import icon_2 from '../../image/Portfolio/img_2.webp'
import icon_3 from '../../image/Portfolio/img_3.webp'
import icon_4 from '../../image/Portfolio/img_4.webp'
import icon_5 from '../../image/Portfolio/img_6.webp'
import icon_6 from '../../image/Portfolio/img_7.webp'


const titleAnimation = {
    hidden: {
        y: 200,
        opacity: 0,
    },

    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3 }
    }
}

const cardAnimation = {
    hidden: {
        y: 100,
        opacity: 0,
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { duration: .3}
    } 
}

export const Services = ({isOpen}) => {

    return (
        <motion.section 
            className={styles.main} 
            id='Services'
            initial='hidden'
            whileInView='visible'
            viewport={{amount: 0.02, once: true}}
            >
                <div className={styles.box}>
                    <motion.h2 className={styles.title} variants={titleAnimation}>Мы предлагаем <span>услуги</span></motion.h2>
                    <motion.ul className={styles.list}>
                    <motion.li 
                        className={styles.item}
                        variants={cardAnimation}
                        initial='hidden'
                        whileInView='visible'
                        viewport={{amount: 0.01, once: true}}
                        >
                        <img className={styles.item_image}  src={icon_1} alt='асенизатор'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Откачка септиков, колодцев, выгребных ям, канализации, био и дачных туалетов. Вывоз ЖБО.</h3>
                                <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://api.whatsapp.com/send?phone=79166382281&text=start' aria-label='связаться вацап'>ЗАКАЗАТЬ</a>
                                <a className={styles.phone}  href="tel:+79166382281" target='_blank' rel='noopener noreferrer'>ЗАКАЗАТЬ</a>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                            <img className={styles.item_image}  src={icon_2} alt='металл'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Устранение засоров, гидродинамическая промывка канализации, механическая прочистка, химическая очистка - полная очистка труб от отложений. Видеоинспекция труб, теледиагностика</h3>
                                <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://api.whatsapp.com/send?phone=79166382281&text=start' aria-label='связаться вацап'>ЗАКАЗАТЬ</a>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_6} alt='металл'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Механизированная, ручная чистка дна колодцев, восстановление пропускной способности. Удаление илового осадка.</h3>
                                <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://api.whatsapp.com/send?phone=79166382281&text=start' aria-label='связаться вацап'>ЗАКАЗАТЬ</a>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_5} alt='металл'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Промывка, чистка труб системы водоотведения и канализации. Прочистка ливневых стоков, канализаций.</h3>
                                <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://api.whatsapp.com/send?phone=79166382281&text=start' aria-label='связаться вацап'>ЗАКАЗАТЬ</a>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_3} alt='откачка воды'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Откачка воды из подвалов, бассейнов, котлованов. Осушение и чистка водоемов.</h3>
                                <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://api.whatsapp.com/send?phone=79166382281&text=start' aria-label='связаться вацап'>ЗАКАЗАТЬ</a>
                            </div>
                        </motion.li>
                        <motion.li 
                            className={styles.item}
                            variants={cardAnimation}
                            initial='hidden'
                            whileInView='visible'
                            viewport={{amount: 0.01, once: true}}
                            >
                        <img className={styles.item_image}  src={icon_4} alt='металл'/>
                            <div className={styles.box_info}>
                                <h3 className={styles.item_title}>Обслуживание автомоек, КНС, АЗС, строительных объектов, пищевых производств. Заключение договоров, предоставление документов об обслуживании</h3>
                                <a className={styles.button} target='_blank' rel='noopener noreferrer' href='https://api.whatsapp.com/send?phone=79166382281&text=start' aria-label='связаться вацап'>ЗАКАЗАТЬ</a>
                            </div>
                        </motion.li>
                    </motion.ul>
                </div>
        </motion.section>
        
    )
}